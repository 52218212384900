<template>
	<div
		shadow="always"
		class="container">
		<div class="flex justify-center">
			<el-image
				class="image-icon"
				:src="require('@/assets/order-plus-with-word-new.png')">
			</el-image>
		</div>
		<el-card shadow="always">
			<el-form
				ref="loginFormRef"
				@submit.prevent="submitSignIn"
				class="login-container"
				:model="{
					email: signInData.email,
					password: signInData.password
				}"
				label-position="top">
				<!-- <h1>Order-Plus</h1> -->
				<div class="title-backoffice">
					Back Office
				</div>
				<el-form-item
					label="email"
					prop="email"
					:rules="[
						{ required: true, message: 'กรุณาระบุอีเมล', trigger: ['change', 'blur'] },
						{ type: 'email', message: 'รูปแบบอีเมลผิด', trigger: ['change', 'blur'] }
					]">
					<el-input
						v-model="signInData.email"
						placeholder="e.g. robert@orderplus.com"
					></el-input>
				</el-form-item>
				<el-form-item
					label="password"
					prop="password"
					:rules="[
						{ required: true, message: 'กรุณาระบุรหัสผ่าน', trigger: ['change', 'blur'] },
						{ min: 8, message: 'กรุณาระบุรหัสผ่านมากกว่าเท่ากับ 8 ตัว', trigger: ['change', 'blur'] }
					]">
					<el-input
						type="password"
						v-model="signInData.password"
						placeholder="password"
						@update:modelValue="value => $emit('update:accountName', value)"></el-input>
				</el-form-item>
				<el-alert
					v-if="signInData.error"
					class="mt-10"
					:title="signInData.error"
					type="error"
					:closable="false"
					show-icon> </el-alert>
				<div class="mt-10">
					<el-button
						:loading="signInData.isSubmiting"
						native-type="submit"
						type="primary">
						เข้าสุ่ระบบ
					</el-button>
				</div>
			</el-form>
		</el-card>
	</div>
</template>

<script>
import { defineComponent, reactive, watchEffect, ref } from 'vue'
import useAuth from '@/use/useAuth'
import { useRouter } from 'vue-router'
import { useStore } from '@/store'
export default defineComponent({
	name: 'Login',
	setup() {
		const loginFormRef = ref()
		const { signInWithEmailAndPassword } = useAuth()
		const router = useRouter()
		const store = useStore()

		const signInData = reactive({
			email: '',
			password: '',
			isSubmiting: false,
			error: ''
		})

		watchEffect(() => {
			if (store.state.user.userData) {
				router.push('/dashboard')
			}
		})

		const submitSignIn = async () => {
			signInData.error = '' // Clear error
			const isFormValid = await loginFormRef.value.validate()
			if (!isFormValid) return
			signInData.isSubmiting = true

			// Watch Effect will be trigger after submit
			signInWithEmailAndPassword(signInData.email, signInData.password)
				.then()
				.catch((error) => {
					signInData.error = error
				})
				.finally(() => signInData.isSubmiting = false)
		}

		return {
			submitSignIn,
			signInData,
			loginFormRef
		}
	}
})
</script>

<style lang="scss" scoped>
.container {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: $bz-primary;
	.login-container {
		width: 26rem;
		min-height: 24rem;
		@include respond-to($md) {
			width: 100%;
			padding: 1.25rem;
		}
		h1 {
			text-align: center;
		}
		.facebook-image {
			width: 1.5rem;
			height: 1.5rem;
			margin-right: 1rem;
			position: absolute;
			left: 2rem;
			bottom: 0;
			top: 0;
			margin: auto 0;
		}
		.facebook-btn {
			position: relative;
			background: #495a96 !important;
			border: none;
			margin-top: 0.75rem;
		}
	}
}
.image-icon {
	width: 10rem;
	height: auto;
}
button {
	width: 100%;
	align-self: flex-start;
}
.title-backoffice {
	font-size: 1.8rem;
	margin-bottom: 1rem;
	text-align: center;
}
</style>