import firebase from 'firebase'
import { store } from '@/store'
import router from '@/router'
import { UserMutationsTypes } from '@/store/modules/user/mutations'

export interface UseAuth {
    currentUser: () => firebase.User | null;
    loginFacebook: () => void;
    loginGoogle: () => void;
	signOut: () => void;
	signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
}

const useAuth = (): UseAuth => {
	const currentUser = () => firebase.auth().currentUser

	const loginFacebook = () => {
		const provider = new firebase.auth.FacebookAuthProvider()
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				/** @type {firebase.auth.OAuthCredential} */
				const credential = (result.credential) as firebase.auth.OAuthCredential
				const firebaseUserData = result.user

				// accessToken.value = credential?.accessToken
				store.commit(UserMutationsTypes.UPDATE_USER_DATA, firebaseUserData)
				store.commit(UserMutationsTypes.UPDATE_PROVIDER_ACCESS_TOKEN, credential.accessToken)
			})
			.catch((error) => {
				console.error('facebook signin error :', error)
			})
	}

	const loginGoogle = () => {
		const provider = new firebase.auth.GoogleAuthProvider()
		firebase
			.auth()
			.signInWithPopup(provider)
			.then((result) => {
				/** @type {firebase.auth.OAuthCredential} */
				const credential = (result.credential) as firebase.auth.OAuthCredential
				const firebaseUserData = result.user
				store.commit(UserMutationsTypes.UPDATE_USER_DATA, firebaseUserData)
				store.commit(UserMutationsTypes.UPDATE_PROVIDER_ACCESS_TOKEN, credential.accessToken)

			})
			.catch((error) => {
				console.error('google signin error : ', error)
			})
	}

	const signInWithEmailAndPassword = (email: string, password: string) => {
		return firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(result => {
				const firebaseUserData = result.user
				store.commit(UserMutationsTypes.UPDATE_USER_DATA, firebaseUserData)
			})
			.catch((error: firebase.auth.Error) => {
				return Promise.reject(firebaseAuthErrorMessageWithErrorCode(error))
			})
	}	

	const signOut = async () => {
		await firebase.auth().signOut()
		// clear all localPersisted data in local storage
		localStorage.removeItem('order-plus-admin')
		await router.push('/login')
	}

	return {
		currentUser,
		loginFacebook,
		loginGoogle,
		signOut,
		signInWithEmailAndPassword
	}
}

const firebaseAuthErrorMessageWithErrorCode = (error: firebase.auth.Error): string => {
	if (error.code === 'auth/user-not-found') {
		return 'ไม่พบผู้ใช้ในระบบกรุณาตรวจสอบอีกครั้ง'
	}
	if (error.code === 'auth/wrong-password') {
		return 'รหัสผ่านไม่ถูกต้องกรุณาลองอีกครั้ง'
	}
	return 'บางอย่างผิดพลาดกรุณาตรวจสอบ'
}

export default useAuth